// if ($(window).width() > 1025) {
// -- -- -- -- -- -- - VARIABLES-- -- -- -- -- -- - //
var ticking = false;
var isFirefox = (/Firefox/i.test(navigator.userAgent));
var isIe = (/MSIE/i.test(navigator.userAgent)) || (/Trident.*rv\:11\./i.test(navigator.userAgent));
var scrollSensitivitySetting = 100; //Increase/decrease this number to change sensitivity to trackpad gestures (up = less sensitive; down = more sensitive) 
var slideDurationSetting = 600; //Amount of time for which slide is "locked"
var currentSlideNumber = 0;
var totalSlideNumber = $(".home-page section").length;

// ------------- DETERMINE DELTA/SCROLL DIRECTION ------------- //
function parallaxScroll(evt) {
    let delta = 0
    if (isFirefox) {
        //Set delta for Firefox
        delta = evt.detail * (-120);
    } else if (isIe) {
        //Set delta for IE
        delta = -evt.deltaY;
    } else {
        //Set delta for all other browsers
        delta = evt.wheelDelta;
    }

    if (ticking != true) {

        if (delta <= -scrollSensitivitySetting) {
            //Down scroll
            ticking = true;
            if (currentSlideNumber !== totalSlideNumber - 1) {
                currentSlideNumber++;
                nextItem();
            }
            slideDurationTimeout(slideDurationSetting);
        }
        if (delta >= scrollSensitivitySetting) {
            //Up scroll
            ticking = true;
            if (currentSlideNumber !== 0) {
                currentSlideNumber--;
            }
            previousItem();
            slideDurationTimeout(slideDurationSetting);
        }
    }
}
document.addEventListener("keydown", checkKeyPressed, false);

function checkKeyPressed(e) {
    if (e.keyCode === 38) {
        ticking = true;
        if (currentSlideNumber !== 0) {
            currentSlideNumber--;
        }
        previousItem();
        slideDurationTimeout(slideDurationSetting);
    }
    if (e.keyCode === 40) {
        ticking = true;
        if (currentSlideNumber !== totalSlideNumber - 1) {
            currentSlideNumber++;
            nextItem();
        }
        slideDurationTimeout(slideDurationSetting);
    }
}
// ------------- SET TIMEOUT TO TEMPORARILY "LOCK" SLIDES ------------- //
function slideDurationTimeout(slideDuration) {
    setTimeout(function() {
        ticking = false;
    }, slideDuration);
}

// ------------- ADD EVENT LISTENER ------------- //
var mousewheelEvent = isFirefox ? "DOMMouseScroll" : "wheel";
window.addEventListener(mousewheelEvent, _.throttle(parallaxScroll, 60), false);

// ------------- SLIDE MOTION ------------- //
function nextItem() {
    $('section').removeClass('current-page')
    var $previousSlide = $(".home-page section").eq(currentSlideNumber - 1);
    var $currentSlide = $(".home-page section").eq(currentSlideNumber);
    $currentSlide.addClass('current-page')
    $previousSlide.removeClass("up-scroll").addClass("down-scroll");
    $('header').addClass('active')
    $('#fake-header-open').addClass('active')
    $('header .search-wrapper').removeClass('active')
        // console.log(currentSlide)
    if (currentSlideNumber == 3) {
        // $('#fake-header-open').addClass('last')
        $('footer').addClass('active')
    } else {

        $('footer').removeClass('active')
    }
    if (currentSlideNumber == 1)
        $('#fake-header-open').addClass('last')
    else
        $('#fake-header-open').removeClass('last')


    $('#nav-pagination ul li').removeClass('active')
    $('#nav-pagination ul li:nth-child(' + (currentSlideNumber + 1) + ')').addClass('active')
        // if ($currentSlide.hasClass('home-banner'))
        //     $('header').removeClass('active')
}


function previousItem() {
    $('section').removeClass('current-page')
    $('footer').removeClass('active')
    var $currentSlide = $(".home-page section").eq(currentSlideNumber);
    $currentSlide.addClass('current-page')
    $('#fake-header-open').removeClass('last')
    if ($currentSlide.is(':first-child')) {
        $('header').removeClass('active')
        $('#fake-header-open').removeClass('active')
    }
    if (currentSlideNumber == 1)
        $('#fake-header-open').addClass('last')
    else
        $('#fake-header-open').removeClass('last')


    $currentSlide.removeClass("down-scroll").addClass("up-scroll");
    $('#nav-pagination ul li').removeClass('active')
    $('#nav-pagination ul li:nth-child(' + (currentSlideNumber + 1) + ')').addClass('active')

}
// }

function setBackgroundElement() {
    $('[setBackground]').each(function() {
        var background = $(this).attr('setBackground')
        $(this).css({
            "background-image": "url(" + background + ")",
            "background-size": "cover",
            "background-position": "center center"
        })
    })
    $('[setBackgroundRepeat]').each(function() {
        var background = $(this).attr('setBackgroundRepeat')
        $(this).css({
            "background-image": "url(" + background + ")",
            "background-repeat": "repeat"
        })
    })
}

function homeBanner() {
    return new Swiper('.home-1 .top-wrapper .swiper-container', {
        speed: 1000,
        pagination: {
            el: '.home-1 .top-wrapper .swiper-pagination',
            clickable: true
        },
        autoplay: {
            delay: 4000
        }
    })
}

function ToggleSearchWrapper() {
    $('header .search-icon, #fake-header .search-icon').on('click', function() {
        $('header .search-wrapper').addClass('active')
    })
    $('header .search-wrapper .close-button').on('click', function() {
        $('header .search-wrapper').removeClass('active')
    })
}

function EqualHeightElement(el) {
    let height = 0;
    let thisHeight = 0;
    $(el).each(function() {
        thisHeight = $(this).height();
        if (thisHeight > height) {
            height = thisHeight;
        }
    });
    $(el).height(height)
}

function EqualWidthElement(el) {
    let width = 0;
    let thiswidth = 0;
    $(el).each(function() {
        thiswidth = $(this).width();
        if (thiswidth > width) {
            width = thiswidth;
        }
    });
    $(el).width(width)
}

function scrollToTop() {
    $('footer .button-wrapper').on('click', function() {
        currentSlideNumber = 0
        $('section').removeClass('down-scroll')
        $('footer').removeClass('active')
        $('header').removeClass('active')
        $('.home-banner').addClass('current-page')
    })
}

function toggleMobileHeader() {
    $('header .toggle-button').on('click', function() {
        $(this).toggleClass('active')
        $('header .mobile-wrapper').toggleClass('active')
    })
}

function customInputText() {
    let selectIconList = ['business_center', 'place', 'menu']
    let textIconList = ['mail', 'phone_iphone']
    $('.home-page .home-2 .content-wrapper .form-wrapper .custom-select').each(function(index) {
        let text = $(this).find('label').text()
        $(this).find('label').remove()
        $(this).prepend('<label><em class=\'material-icons\'>' + selectIconList[index] + '</em></label>')
            // $(this).find('select').prepend('<option value=\'\' selected>' + text + '</option>')
    })
    $('.home-page .home-2 .content-wrapper .form-wrapper .custom-input').each(function(index) {
        $(this).find('label').prepend('<em class=\'material-icons\'>' + textIconList[index] + '</em>')
    })
    $('.custom-input, .custom-select').each(function() {
        let labelWid = $(this).find('label').outerWidth()
        $(this).find('input').css({
            'padding-left': labelWid + 15 + 'px'
        })
        $(this).find('select').css({
            'padding-left': labelWid + 'px'
        })
    })
}

function mobileMapping() {
    return {
        'FakeHeaderOpen': new MappingListener({
            selector: '#fake-header-open',
            mobileWrapper: '.kfc-header .main-wrapper',
            mobileMethod: 'appendTo',
            desktopWrapper: 'main',
            desktopMethod: 'prependTo',
            breakpoint: 1025,
        }).watch()
    }
}

function recruitmentTab() {
    $('.home-1 .main-wrapper .nav-tab .tab-item').on('click', function() {
        let tabId = $(this).attr('data-tab')
        $('.home-1 .main-wrapper .nav-tab .tab-item').removeClass('active')
        $(this).addClass('active')
        $('.home-1 .main-wrapper .nav-content .content-item').removeClass('active')
        $(`.home-1 .main-wrapper .nav-content .content-item[data-tab=${tabId}]`).addClass('active')
        let recruitmentSlider = new Swiper('.home-1 .bottom-wrapper .swiper-container', {
            slidesPerView: 4,
            speed: 1000,
            spaceBetween: 10,
            navigation: {
                nextEl: '.home-1 .bottom-wrapper .swiper-next',
                prevEl: '.home-1 .bottom-wrapper .swiper-prev'
            },
            breakpoints: {
                1025: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 1
                }
            },
            observer: true,
            observeParents: true
        })
        EqualHeightElement('.home-page .home-1 .main-wrapper .nav-content .item .title')
        EqualHeightElement('.home-page .home-1 .main-wrapper .nav-content .item .d-flex')
    })
    $('.home-1 .main-wrapper .nav-tab .tab-item:first-child').trigger('click')
}

function newsTab() {
    $('.home-3 .nav-tab .tab-item').on('click', function() {
        let tabId = $(this).attr('data-tab')
        $('.home-3 .nav-tab .tab-item').removeClass('active')
        $(this).addClass('active')
        $('.home-3 .nav-content .content-item').removeClass('active')
        $(`.home-3 .nav-content .content-item[data-tab=${tabId}]`).addClass('active')
        let newsSlider = new Swiper('.home-3 .nav-content .content-item .swiper-container', {
            slidesPerView: 2,
            speed: 1000,
            spaceBetween: 30,
            navigation: {
                nextEl: '.home-3 .nav-content .content-item .swiper-next',
                prevEl: '.home-3 .nav-content .content-item .swiper-prev'
            },
            observer: true,
            observeParents: true,
            breakpoints: {
                768: {
                    slidesPerView: 1
                }
            },
        })
    })
    $('.home-3 .nav-tab .tab-item:first-child').trigger('click')
}

function createPagination() {
    $('main').append("<div id=\"nav-pagination\"><ul></ul></div>")
    let list = $('.home-page section, footer')
    for (let i = 0; i < totalSlideNumber; i++) {
        if (i == 0) {
            $('#nav-pagination ul').append('<li class=\'active\' data-section=\'' + (i + 1) + '\'>' + (i + 1) + '<span>' + $(list[i]).attr('data-title') + '</span></li>')
        } else {
            $('#nav-pagination ul').append('<li data-section=\'' + (i + 1) + '\'>' + (i + 1) + '<span>' + $(list[i]).attr('data-title') + '</span></li>')
        }
    }
    $('#nav-pagination ul li').on('click', function() {
        $('#nav-pagination ul li').removeClass('active')
        $(this).addClass('active')
        let e = $(this).attr('data-section') - 1
        $('.page-wrap section').removeClass('down-scroll up-scroll')
        if (e < currentSlideNumber) {
            currentSlideNumber = e
            $('.page-wrap section:nth-child(' + (currentSlideNumber + 1) + ')').addClass('up-scroll current-page')
            $('.page-wrap section').removeClass('down-scroll')
            for (let i = currentSlideNumber; i < totalSlideNumber; i++) {
                $('.page-wrap section:nth-child(' + (currentSlideNumber + 1) + ')').addClass('up-scroll')
            }
        } else {
            currentSlideNumber = e
            $('.page-wrap section').removeClass('current-page')
            $('.page-wrap section:nth-child(' + (currentSlideNumber + 1) + ')').addClass('current-page')
            for (let i = 0; i < currentSlideNumber; i++) {
                $('.page-wrap section:nth-child(' + (i + 1) + ')').addClass('down-scroll')
            }
        }
        if ((currentSlideNumber + 1) == totalSlideNumber) {
            $('footer').addClass('active')
        } else {
            $('footer').removeClass('active')
        }
        if (currentSlideNumber != 0) {
            $('header').addClass('active')
            $('#fake-header-open').addClass('active')
        } else {
            $('header').removeClass('active')
            $('#fake-header-open').removeClass('active')
        }
    })
}

function fakeHeader() {
    if ($('#fake-header').length) {
        let e = $('header .list-page-wrapper').html()
        $('#fake-header').append(e)
        $('[data-mobile-title]').each(function() {
            if ($(this).attr('data-mobile-class') != undefined) {

                $('#fake-header ul').append('<li><a class=' + $(this).attr('data-mobile-class') + ' href=' + $(this).find('a').attr('href') + '>' + $(this).attr('data-mobile-title') + '</a></li>')
            } else {

                $('#fake-header ul').append('<li><a href=' + $(this).find('a').attr('href') + '>' + $(this).attr('data-mobile-title') + '</a></li>')
            }

        })
        $('#fake-header-open').on('click', function() {
            $('#fake-header').addClass('active')
        })
        $('#fake-header .close-button').on('click', function() {
            $('#fake-header').removeClass('active')
        })
    }
}

function YearNav() {
    $('.gioi-thieu-2 .year-nav .item').on('click', function() {
        let yearId = $(this).attr('data-item')
        $('.gioi-thieu-2 .year-nav .item').removeClass('active')
        $(this).addClass('active')
        $('.gioi-thieu-2 .year-content .item').removeClass('active')
        $(`.gioi-thieu-2 .year-content .item[data-item=${yearId}]`).addClass('active')
        if ($(window).width() < 1200) {
            $('.gioi-thieu-2 .year-nav').stop().animate({
                'scrollLeft': $(this).width() * parseInt(yearId - 1) - $(this).width() * 2
            }, 500);
        } else {
            $('.gioi-thieu-2 .year-nav').stop().animate({
                'scrollTop': $(this).outerHeight() * parseInt(yearId - 1) - $(this).outerHeight() * 2
            }, 500);
        }
        if ($(this).next().length == 0) {
            $('.gioi-thieu-2 .year-arrow .year-next').addClass('disable')
        } else {
            $('.gioi-thieu-2 .year-arrow .year-next').removeClass('disable')
        }
        if ($(this).prev().length == 0) {
            $('.gioi-thieu-2 .year-arrow .year-prev').addClass('disable')
        } else {
            $('.gioi-thieu-2 .year-arrow .year-prev').removeClass('disable')
        }
    })
    $('.gioi-thieu-2 .year-arrow .year-next').on('click', function() {
        $('.gioi-thieu-2 .year-nav .item.active').next().trigger('click')
    })
    $('.gioi-thieu-2 .year-arrow .year-prev').on('click', function() {
        $('.gioi-thieu-2 .year-nav .item.active').prev().trigger('click')
    })
    $('.gioi-thieu-2 .year-nav .item:nth-child(3)').trigger('click')
}

function kfcListImage() {
    return new Swiper('.gioi-thieu-5 .swiper-container', {
        slidesPerView: 1,
        speed: 2000,
        spaceBetween: 4,
        loop: true,
        navigation: {
            nextEl: '.gioi-thieu-5 .swiper-next'
        }
    })
}

function initSlider() {
    let newsOtherSlider = new Swiper('.tin-tuc-ct-2 .swiper-container', {
        slidesPerView: 3,
        speed: 1000,
        spaceBetween: 30,
        breakpoints: {
            1025: {
                slidesPerView: 2
            },
            476: {
                slidesPerView: 1
            }
        },
        navigation: {
            prevEl: '.tin-tuc-ct-2 .swiper-prev',
            nextEl: '.tin-tuc-ct-2 .swiper-next'
        }
    })
}

function initWowBook() {
    $('#features').wowBook({
        height: 878,
        width: 1240,
        centeredWhenClosed: true,
        hardcovers: true,
        turnPageDuration: 1000,
        numberedPages: [1, -2],
        controls: {
            zoomIn: '#zoomin',
            zoomOut: '#zoomout',
            next: '#next',
            back: '#back',
            first: '#first',
            last: '#last',
            slideShow: '#slideshow',
            flipSound: '#flipsound',
            thumbnails: '#thumbs',
            fullscreen: '#fullscreen'
        },
        scaleToFit: "#container",
        thumbnailsPosition: 'bottom',
        onFullscreenError: function() {
            var msg = "Fullscreen failed.";
            if (self != top) msg = "The frame is blocking full screen mode. Click on 'remove frame' button above and try to go full screen again."
            alert(msg);
        }
    }).css({ 'display': 'none', 'margin': 'auto' }).fadeIn(1000);

    $("#cover").click(function() {
        $.wowBook("#features").advance();
    });

    var book = $.wowBook("#features");

    function rebuildThumbnails() {
        book.destroyThumbnails()
        book.showThumbnails()
        $("#thumbs_holder").css("marginTop", -$("#thumbs_holder").height() / 2)
    }
    $("#thumbs_position button").on("click", function() {
        var position = $(this).text().toLowerCase()
        if ($(this).data("customized")) {
            position = "top"
            book.opts.thumbnailsParent = "#thumbs_holder";
        } else {
            book.opts.thumbnailsParent = "body";
        }
        book.opts.thumbnailsPosition = position
        rebuildThumbnails();
    })
    $("#thumb_automatic").click(function() {
        book.opts.thumbnailsSprite = null
        book.opts.thumbnailWidth = null
        rebuildThumbnails();
    })
    $("#thumb_sprite").click(function() {
        book.opts.thumbnailsSprite = "images/thumbs.jpg"
        book.opts.thumbnailWidth = 136
        rebuildThumbnails();
    })
    $("#thumbs_size button").click(function() {
        var factor = 0.02 * ($(this).index() ? -1 : 1);
        book.opts.thumbnailScale = book.opts.thumbnailScale + factor;
        rebuildThumbnails();
    })
}
$(document).ready(function() {
    homeBanner()
    kfcListImage()
    setBackgroundElement()
    scrollToTop()
    toggleMobileHeader()
    newsTab()
    createPagination()
    mobileMapping()
    YearNav()
    recruitmentTab()
    fakeHeader()
    ToggleSearchWrapper()
    initSlider()
    initWowBook()
    setTimeout(() => {
        $('section:first-child').addClass('current-page')
        customInputText()
        EqualHeightElement('.news-item figure figcaption h5')
        EqualHeightElement('.tin-tuc .bottom-wrapper .news-item figure .image img')
        EqualHeightElement('.tin-tuc-ct-2 .news-item figure .image img')
    }, 1500);
    EqualHeightElement('.thu-vien-anh .list-item .small-item figure .image')
    $('[data-fancybox').fancybox({
        thumbs: {
            autoStart: true,
            axis: 'x'
        }
    });
    $(".social-share").jsSocials({
        showLabel: false,
        showCount: false,
        shares: [{
            share: 'twitter',
            logo: 'fab fa-twitter'
        }, {
            share: 'pinterest',
            logo: 'fab fa-pinterest'
        }, {
            share: 'facebook',
            logo: 'fab fa-facebook-f'
        }]
    })
    $('.cau-hoi-1 .left-nav ul li em').on('click', function() {
        $(this).parent().toggleClass('active')
        $(this).siblings('.sub-list').slideToggle()
    })
    $('.cau-hoi-1 .right-content .accordion-list .item .title').on('click', function() {

        $('.cau-hoi-1 .right-content .accordion-list .item .title').removeClass('active')
        $('.cau-hoi-1 .right-content .accordion-list .item .description').slideUp()
        if ($(this).siblings().is(':hidden')) {
            $(this).addClass('active')
            $(this).siblings().slideDown()
        } else {
            $(this).removeClass('active')
            $(this).siblings().slideUp()
        }
    })
    $('.gioi-thieu-4 .list-item .item').on('click', function() {
        let e = $(this).attr('data-image')
        $('.gioi-thieu-4 .image-wrapper img').attr('src', e)
    })
    $('.kfc-header .interview-wrapper a').on('click', function() {
        $('.recruitment-model-wrapper').addClass('active')
    })
    $('.recruitment-model-wrapper .close-button').on('click', function() {
        $('.recruitment-model-wrapper').removeClass('active')
    })
    $('.file-upload>div>span').each(function() {
        $(this).appendTo($(this).parents('.file-upload'))
    })
    EqualWidthElement('.tuyen-dung-ct .content-wrapper .full-content .table tr td:last-child')
        // $('.tuyen-dung-ct .content-wrapper .full-content .table tr td:first-child').each(function() {})
})